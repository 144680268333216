import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { imageShowUrl } from "../../../../config/api.config";
import { API } from "services/http.service";

const Competition = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const [loaded, setLoaded] = useState(true);
  const [priceListImage, setPriceListImage] = useState(
    "/portal-resource/img/1155.png"
  );
  const [disclaimerList, setDisclaimerList] = useState([]);

  useEffect(() => {
    if (!disclaimerList || disclaimerList.length === 0) {
      dispatch(
        gameAction.getCompetitionDisclaimerByFirmId(
          employeeFirm.firm.id,
          employeeFirm.id
        )
      ).then(response => {
        setLoaded(true);
        setDisclaimerList(response.data);
      });
    }

    return () => {};
  }, []);

  useEffect(() => {
    const asyncPriceListImageData = async () => {
      const { data } = await API.get("price/PriceList");
      setPriceListImage(data);
    };
    asyncPriceListImageData();
  }, []);

  return (
    <div
      className="container wrapper-question-item !rounded-2xl"
      style={{
        color:
          disclaimerList && disclaimerList[0] && disclaimerList[0].primaryColor
            ? disclaimerList[0].primaryColor
            : "#FFF"
      }}
    >
      <GlobalLoader isLoading={!loaded} />
      <div className="text-center">
        <a
          data-fancybox="gallery"
          href={imageShowUrl + priceListImage}
          className="price-list-img img-fluid"
        >
          <img
            src={imageShowUrl + priceListImage}
            className="price-image img-fluid"
            alt="Fiyat Listesi"
          />
        </a>
      </div>
    </div>
  );
};
export default Competition;
