import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import { Redirect } from "react-router-dom";

import CityMapImg from "assets/images/games/choose-city-map.png";
import MainLogo from "assets/images/games/main-logo-2.png";

const City = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const controlCompetition = useSelector(
    store => store.game.controlCompetition
  );
  var competition = null;
  const isLoading = useSelector(store => store.game.isLoading);
  const disclaimerList = useSelector(store => store.game.disclaimerList);
  var disclaimer = null;

  useEffect(() => {
    dispatch(
      gameAction.getControlCompetitionByFirmId(
        employeeFirm.firm.id,
        employeeFirm.id
      )
    );
    dispatch(
      gameAction.getCompetitionDisclaimerByFirmId(
        employeeFirm.firm.id,
        employeeFirm.id
      )
    );
    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, [controlCompetition, disclaimerList]);

  if (disclaimerList) {
    disclaimer = disclaimerList.map(item => {
      return (
        <div key={item.id}>
          {item.checkedDisclaimerCompetition ? null : (
            <Redirect to={{ pathname: "/game/gameRules" }} />
          )}
        </div>
      );
    });
  } else {
    disclaimer = null;
  }

  if (controlCompetition) {
    competition = controlCompetition.map((item, index) => {
      return (
        <div key={index}>
          <div className="inner-text">
            <div className="map">
              <img src={CityMapImg} alt="Şehir Seç Görseli" />
              {competition}
              <div className="city-img">
                <span>{item.cityName}</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div>
      <GlobalLoader isLoading={isLoading} />
      {disclaimer}
      <section className="page main choose-city">
        <div className="container">
          <div className="main-img">
            <img src={MainLogo} alt="Main Logo" />
          </div>
          {competition}
        </div>
      </section>
    </div>
  );
};
export default City;
