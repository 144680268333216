import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import { Redirect } from "react-router-dom";
import { imageShowUrl } from "../../../config/api.config";

import "animate.css";

import Error1 from "../competition/assets/images/fair/error-1.jpg";
import Error2 from "../competition/assets/images/fair/error-2.jpg";
import Error3 from "../competition/assets/images/fair/error-3.jpg";

import Success1 from "../competition/assets/images/fair/success-1.jpg";
import Success2 from "../competition/assets/images/fair/success-2.jpg";
import Success3 from "../competition/assets/images/fair/success-3.jpg";

const CompetitionRule = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  var disclaimer = null;
  const [disclaimerList, setDisclaimerList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [competitionControl, setCompetitionControl] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const controlCompetition = useSelector(
    store => store.game.controlCompetition
  );

  useEffect(() => {
    getQuestionList();

    if (!disclaimerList || disclaimerList.length === 0) {
      dispatch(
        gameAction.getCompetitionDisclaimerByFirmId(
          employeeFirm.firm.id,
          employeeFirm.id
        )
      ).then(response => {
        setLoaded(true);
        setDisclaimerList(response.data);
      });
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (controlCompetition && controlCompetition.length > 0) {
      localStorage.setItem("compId", controlCompetition[0].compId);
    }
  }, [controlCompetition]);

  function getQuestionList() {
    if (!competitionControl) {
      // get question list
      dispatch(
        gameAction.getControlCompetitionByFirmId(
          employeeFirm.firm.id,
          employeeFirm.id
        )
      )
        .then(response => {
          if (!global._.isEmpty(response)) {
            let questionList = global._.sortBy(response.data, [
              function(o) {
                return o.questionRank;
              }
            ]);

            let arr = questionList[0].startTime.split(/[- :]/),
              startDate = new Date(
                arr[0],
                arr[1] - 1,
                arr[2],
                arr[3],
                arr[4],
                arr[5]
              );

            let arr1 = questionList[0].endTime.split(/[- :]/),
              endDate = new Date(
                arr1[0],
                arr1[1] - 1,
                arr1[2],
                arr1[3],
                arr1[4],
                arr1[5]
              );

            if (!questionList[0].activeQuestion) {
              if (questionList.length > 0) {
                setStartTime(
                  questionList[0].startTime.split(" ")[1].split(":")[0] +
                    ":" +
                    questionList[0].startTime.split(" ")[1].split(":")[1]
                );
              }
            }

            // time control end
          }
          setCompetitionControl(true);
        })
        .catch(error => {
          // setStartTime("10:00");
          setCompetitionControl(true);
        });
    }
  }

  var competitionMessage = "";

  if (startTime != null) {
    competitionMessage = (
      <h5>Yarışmamız Saat {startTime} da başlayacaktır. </h5>
    );
  }

  if (disclaimerList && disclaimerList.length > 0 && competitionControl) {
    disclaimer = disclaimerList.map((item, index) => {
      return (
        <div key={index} className="wrapper-question-item main-page-question">
          {item.checkedDisclaimerCompetition ? (
            <>
              {item.competitionTitle && (
                <h1
                  style={{ color: item.primaryColor }}
                  dangerouslySetInnerHTML={{ __html: item.competitionTitle }}
                />
              )}

              {item.competitionSubTitle && (
                <h3
                  style={{ color: item.primaryColor }}
                  className="main-question-h3 text-center"
                  dangerouslySetInnerHTML={{ __html: item.competitionSubTitle }}
                />
              )}

              <div className="text-center">
                <div>
                  <img
                    src={
                      item.competitionImage &&
                      imageShowUrl + item.competitionImage
                    }
                    className="gift-image mt-2 mb-2 mx-auto h-36"
                  />
                </div>

                {item.buttonList &&
                  item.buttonList.length > 0 &&
                  item.buttonList.map((btn, btnIndex) => {
                    return (
                      <div
                        className={`wrapper-question-button item-${btnIndex}`}
                        key={btnIndex}
                      >
                        <a
                          className="btn start-button competition-buttons"
                          href={btn.path && btn.path}
                          style={{
                            backgroundColor: `#${btn.buttonColor}`,
                            color: "#fff !important"
                          }}
                        >
                          {btn.name && btn.name}
                        </a>
                      </div>
                    );
                  })}
              </div>
            </>
          ) : (
            <Redirect to="/competition/rules" />
          )}
        </div>
      );
    });
  } else {
    /* return <Redirect to="/" />; */
    disclaimer = <div></div>;
  }

  return (
    <div
      style={{
        color:
          disclaimerList && disclaimerList[0] && disclaimerList[0].primaryColor
            ? disclaimerList[0].primaryColor
            : "#FFF"
      }}
      className="rounded-2xl"
    >
      {disclaimer}
    </div>
  );
};
export default CompetitionRule;
