import React, { Component, useState, useEffect } from "react";
import Menu from "./menu";
import { imageShowUrl } from "../../../config/api.config";

// Ramazan Yarışması
// import RamadanLights from "../../../components/RamadanLights";

// import MoonstarLeft from "../../../assets/images/ramadan-2024/moonstar-left.png";
// import MoonstarRight from "../../../assets/images/ramadan-2024/moonstar-right.png";

// import LightsLeft from "../../../assets/images/ramadan-2024/anim/left-lights.png";
// import LightsLeftActive from "../../../assets/images/ramadan-2024/anim/left-lights-active.png";
// import LightsRight from "../../../assets/images/ramadan-2024/anim/right-lights.png";
// import LightsRightActive from "../../../assets/images/ramadan-2024/anim/right-lights-active.png";

// import KandilLeft from "../../../assets/images/ramadan-2024/kandil-left.png";
// import KandilRight from "../../../assets/images/ramadan-2024/kandil-right.png";

import FairImg from "../../../assets/images/fair/fair-area.png";

const layout = OriginalComponent => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const isLoadTimeout = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(isLoadTimeout);
  }, []);
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = { disclaimerList: null };
      this.gameActionList = this.gameActionList.bind(this);
    }

    gameActionList(param) {
      this.setState({ disclaimerList: param });
    }

    render() {
      require("./assets/scss/main.scss");
      return (
        <main className="min-h-[100vh] competition competition-ramadan">
          {this.state.disclaimerList && (
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                minHeight: "100vh",
                backgroundImage: `url(${imageShowUrl +
                  this.state.disclaimerList[0].backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "bottom"
              }}
            ></div>
          )}

          <div className="header relative">
            {this.gameActionList && (
              <Menu getActionList={this.gameActionList} />
            )}
            <div className="animate__animated animate__slideInLeft mobile-banner pl-4 pr-4 pb-2 text-center">
              {this.state.disclaimerList && (
                <img
                  src={imageShowUrl + this.state.disclaimerList[0].bannerImage}
                  className="col-12 col-sm-7 mx-auto md:mx-0"
                  alt=""
                />
              )}
            </div>

            {/* Ramazan Yarışması */}
            {/* <div className="moonstar-left">
              <img src={MoonstarLeft} alt="" />
            </div> */}
          </div>

          <div className="container-xl content">
            <div className="row">
              <div className="left-content-wrapper col-xl-6 col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
                {this.state.disclaimerList && (
                  <img
                    src={
                      imageShowUrl + this.state.disclaimerList[0].bannerImage
                    }
                    className="img-fluid mw-450"
                    alt=""
                  />
                )}
              </div>

              <div className="right-content-wrapper col-xl-6 col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center animate__animated animate__fadeIn position-relative">
                <div className="right-content">
                  <OriginalComponent {...this.props} />
                  {isLoaded === true && (
                    <img
                      src={FairImg}
                      className="img-fluid position-absolute top-[0] z-[-1] fair-banner hidden lg:block"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="container-xl">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                {this.state.disclaimerList && (
                  <div className="flex flex-row justify-between relative min-h-[300px]">
                    {this.state.disclaimerList[0].backgroundBottomImage1 && (
                      <img
                        src={
                          imageShowUrl +
                          this.state.disclaimerList[0].backgroundBottomImage1
                        }
                        className="img-fluid comp-hacivat"
                      />
                    )}

                    {this.state.disclaimerList[0].backgroundBottomImage2 && (
                      <img
                        src={
                          imageShowUrl +
                          this.state.disclaimerList[0].backgroundBottomImage2
                        }
                        className="img-fluid comp-karagoz"
                      />
                    )}

                    {/* {this.state.disclaimerList[0].backgroundBottomImage3 && (
                      <img
                        src={
                          imageShowUrl +
                          this.state.disclaimerList[0].backgroundBottomImage3
                        }
                        className="img-fluid"
                      />
                    )}

                    {this.state.disclaimerList[0].backgroundBottomImage4 && (
                      <img
                        src={
                          imageShowUrl +
                          this.state.disclaimerList[0].backgroundBottomImage4
                        }
                        className="img-fluid"
                      />
                    )} */}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Ramazan Yarışması */}
          {/* <div className="lights-left">
            <RamadanLights
              inactiveImage={LightsLeft}
              activeImage={LightsLeftActive}
            />
          </div>

          <div className="moonstar-right">
            <img src={MoonstarRight} alt="" />
          </div>

          <div className="kandil-left">
            <img src={KandilLeft} />
          </div>

          <div className="kandil-right">
            <img src={KandilRight} />
          </div>

          <div className="lights-right">
            <RamadanLights
              inactiveImage={LightsRight}
              activeImage={LightsRightActive}
            />
          </div> */}
        </main>
      );
    }
  };
};

export default layout;
